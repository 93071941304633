#mainTemp img {
  margin: 0;
  position: static;
  margin-left: 15px;
}
#mainTemp h2 {
  color: #23272b;
  text-align: center;
  margin-left: 15px;
}
#city-info {
  text-align: center;
  position: static;
}
h3 .city-list {
  list-style: none;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 10px;
}
.city-list li {
  padding: 3px;
}
li:first-letter {
  text-transform: capitalize;
}
#description {
  font-size: 23px;
  margin-top: 10px;
  color: #e2e6ea;
  font-weight: 900;
  text-shadow: 1px 1px #48484a;
  position: static;
}
