.Extras {
  font-size: 13px;
}
.Extras ul {
  margin: 20px;
  padding: 0;
  list-style: none;
  text-align: left;
}
.Extras li {
  padding: 0;
}

#suggestion {
  margin: 20px;
  margin-bottom: 0;
  padding: 0;
  font-size: 14.5px;
  color: #ec6e4c;
}
