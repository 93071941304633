#coder {
  margin-top: 5px;
  text-align: center;
  font-size: 10px;
}
.row {
  text-align: center;
}
@viewport {
  width: device-width;
  zoom: 1;
}
