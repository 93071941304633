.App {
  font-family: sans-serif;
  text-align: center;
}
.container {
  color: #343a40;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h1 {
  margin-bottom: 8px;
  padding: 0;
}

#coder {
  margin-top: 5px;
  text-align: center;
  font-size: 10px;
}
