.wrapcode {
  position: relative;
  background-image: radial-gradient(#00f2fe 0%, #4facfe 100%);
  background-size: cover;
  max-width: 500px;
  height: 590px;
  margin: auto;
  margin-top: 15px;
  padding: 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 5px 6px 14px;
  overflow: auto;
}
.weather-form form {
  text-align: center;
  margin: 15px;
  position: static;
}
.weather-form form #city-input {
  background-color: transparent;
  box-shadow: 3px 3px 5px;
  border: none;
}
.btn-dark {
  margin-right: 5px;
  margin-bottom: 15px;
}
.btn-light {
  background-color: #d1dbe0;
  margin-bottom: 15px;
  border: none;
}
